var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"w-100 d-flex flex-column card-height"},[_c('div',{staticClass:"w-100 sticky-top d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center ml-4"},[_c('v-card',{staticClass:"h-fit elevation-0",attrs:{"width":"fit-content"}},[_c('div',{staticClass:"text-h6 word-break-word"},[_vm._v("Course Content")])])],1),_c('v-icon',{staticClass:"cursor mr-2",on:{"click":function($event){return _vm.goToRoute('ProductView', {
          subjectId: _vm.$route.query?.subjectId,
          subject: _vm.$route.query?.subject,
        })}}},[_vm._v("mdi-close")])],1),_c('v-card',{staticClass:"elevation-0 list-card"},[_c('v-list',{staticClass:"pa-0"},[_c('v-card',{staticClass:"elevation-0"},[_c('v-expansion-panels',{staticClass:"mx-0 pa-0",attrs:{"active-class":"list-active ","variant":"accordion"},model:{value:(_vm.unitIdFromRouter),callback:function ($$v) {_vm.unitIdFromRouter=$$v},expression:"unitIdFromRouter"}},_vm._l((_vm.selectedSubject?.units),function(item,i){return _c('v-expansion-panel',{key:i,staticClass:"ma-0 pa-0 border-outlined overflow-hidden"},[_c('v-expansion-panel-header',[_c('div',{staticClass:"w-100 d-flex mxpx-12 flex-column"},[_c('div',{staticClass:"pa-0 mbpx-10 course-sub-title-name text-overline"},[_vm._v(" Unit "+_vm._s(i + 1)+":"+_vm._s()+" ")]),_c('div',{class:[
                    'ma-0',
                    'pa-0',
                    'course-title',
                    'text-subtitle-1',
                    { 'onColor-T': item.indexing === _vm.unitIdFromRouter },
                  ]},[_c('span',{staticClass:"cursor text-container"},[_vm._v(" "+_vm._s(item?.unitName)+" ")])])])]),_c('v-expansion-panel-content',{staticClass:"px-0 mx-0 overflow-hidden m-expansion-panel-content pa-0"},[_c('div',{staticClass:"m-nav-unit-list"},_vm._l((item?.chapters),function(chapter,j){return _c('div',{key:j,staticClass:"roundex-xl px-0 px-0"},[_c('v-list-item',{staticClass:"ma-0 pa-0 py-1"},[_c('div',{staticClass:"cursor mx-6 my-2 text-container",class:{ 'clicked-chapter': _vm.chapterFromRouter == chapter?.chapterName },on:{"click":function($event){return _vm.chapterspage(i, j, item.unitName, chapter.chapterName)}}},[_vm._v(" Chapter "+_vm._s(j + 1)+" : "+_vm._s(chapter?.chapterName)+" ")])])],1)}),0)])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }